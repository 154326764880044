.fs-spinner {
    background-color: rgba($color: #222222, $alpha: 0.1);
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    text-align: center;
}