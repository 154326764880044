$primary: #7483ad;
$secondary: #727683;
$success: #7fd67f;
$light: #e1e3e7;
$dark: #343a4b;
$font-size-base: 1.2rem;

.dark {
    $primary: #012345;
    $secondary: #111111;
    $success: #222222;
    $dark: #000;
    $white: #000;

    $font-size-base: 1.2rem;
    $theme-colors: ("primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "dark": $dark,
        "white": $white,
    );

    @import '~bootstrap/scss/bootstrap';

    /* redefine theme color variables */
    @each $color,
    $value in $theme-colors {
        --#{$variable-prefix}#{$color}: #{$value};
    }

    /* redefine theme color rgb vars (used for bg- colors) */
    $theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");

    @each $color,
    $value in $theme-colors-rgb {
        --#{$variable-prefix}#{$color}-rgb: #{$value};
    }

    $body-color: #eeeeee;
    $body-bg: #242525;

    --#{$variable-prefix}body-color: #{$body-color};
    --#{$variable-prefix}body-bg: #{$body-bg};
}

$nav-height: 51px;
$full-height: calc(100% - $nav-height);

.full-height {
    height: $full-height !important;
}

@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons.scss';